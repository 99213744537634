import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thirtyfive',
  templateUrl: './thirtyfive.component.html',
  styleUrls: ['./thirtyfive.component.css']
})
export class ThirtyfiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
